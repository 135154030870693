// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-hcc-awareness-tsx": () => import("./../../../src/pages/hcc-awareness.tsx" /* webpackChunkName: "component---src-pages-hcc-awareness-tsx" */),
  "component---src-pages-hepatitis-b-management-patient-education-tsx": () => import("./../../../src/pages/hepatitis-b-management/patient-education.tsx" /* webpackChunkName: "component---src-pages-hepatitis-b-management-patient-education-tsx" */),
  "component---src-pages-hepatitis-b-management-tsx": () => import("./../../../src/pages/hepatitis-b-management.tsx" /* webpackChunkName: "component---src-pages-hepatitis-b-management-tsx" */),
  "component---src-pages-hepatitis-b-resources-tsx": () => import("./../../../src/pages/hepatitis-b-resources.tsx" /* webpackChunkName: "component---src-pages-hepatitis-b-resources-tsx" */),
  "component---src-pages-hepatitis-b-risk-screening-tsx": () => import("./../../../src/pages/hepatitis-b-risk/screening.tsx" /* webpackChunkName: "component---src-pages-hepatitis-b-risk-screening-tsx" */),
  "component---src-pages-hepatitis-b-risk-tsx": () => import("./../../../src/pages/hepatitis-b-risk.tsx" /* webpackChunkName: "component---src-pages-hepatitis-b-risk-tsx" */),
  "component---src-pages-hepatitis-b-screening-diagnosis-tsx": () => import("./../../../src/pages/hepatitis-b-screening-diagnosis.tsx" /* webpackChunkName: "component---src-pages-hepatitis-b-screening-diagnosis-tsx" */),
  "component---src-pages-hepatitis-b-treatment-tsx": () => import("./../../../src/pages/hepatitis-b-treatment.tsx" /* webpackChunkName: "component---src-pages-hepatitis-b-treatment-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

