/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/index.scss";
import "intersection-observer";

export const onRouteUpdate = function(e) {
  if (window.dataLayer) {
    window.dataLayer.push({ event: "optimize.activate" });

    if (e.location.pathname.indexOf("/hepatitis-b-screening-diagnosis") >= 0) {
      window.dataLayer.push({ event: "optimize.runHeroTest" });
    }
    if (e.location.pathname.indexOf("/hepatitis-b-treatment") >= 0) {
      window.dataLayer.push({ event: "optimize.runCtaTest" });
    }
  }
};
